import { defineStore } from "pinia"

const useErrorModal = defineStore("errorModal", {
  state: () => ({
    isShow: false,
    message: "",
    btnText: "OK",
    submit: null
  }),
})

export default useErrorModal