<template>
  <div class="body-wrapper">
    <WebHeader v-show="commonStore.isWithLogo" />
    <ErrorModal />
    <div class="body-container">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import WebHeader from '@/components/WebHeader'
import ErrorModal from '@/components/ErrorModal'
import useCommonStore from "@/stores/modules/commonStore";

const commonStore = useCommonStore();

</script>

