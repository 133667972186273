<script setup>
/* エラーメッセージモーダル */
import { ref, watch, onMounted, onUnmounted } from "vue";
import useErrorModal from "@/stores/modules/errorModal";

const errorModalStore = useErrorModal();
const pageYOffset = ref(0);

const hideModel = () => {
  errorModalStore.isShow = false;
  errorModalStore.submit && errorModalStore.submit();
  errorModalStore.submit = null;
};
const setPageYOffset = () => {
  pageYOffset.value =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;
};

/* ======watch====== */
watch(
  () => errorModalStore.isShow,
  (newVal) => {
    if (newVal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }
);

onMounted(() => {
  setPageYOffset();
  window.addEventListener("scroll", setPageYOffset);
});
onUnmounted(() => {
  window.removeEventListener("scroll", setPageYOffset);
});
</script>

<template>
  <div
    class="modal-background"
    v-show="errorModalStore.isShow"
    :style="{ top: `${pageYOffset}px` }"
  >
    <div class="modal">
      <p>{{ errorModalStore.message }}</p>
      <button @click="hideModel" class="button">
        {{ errorModalStore.btnText }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
