<script setup>
/* 確認用モーダル */
import { ref, onMounted, onUnmounted, defineProps } from "vue";
import IconSpinner from "@/components/IconSpinner";

const pageYOffset = ref(0);

// モーダル表示位置の補正
const setPageYOffset = () => {
  pageYOffset.value =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;
};

// 親コンポーネントからのパラメータ
const props = defineProps({
  title:{
    type:String
  },
  confirmInfo: {
    type: Array,
  },
  isLoading: {
    type: Boolean,
  },
  onCancel: {
    type: Function,
  },
  onSubmit: {
    type: Function,
  },
});

onMounted(() => {
  setPageYOffset();
  window.addEventListener("scroll", setPageYOffset);
});
onUnmounted(() => {
  window.removeEventListener("scroll", setPageYOffset);
});
</script>

<template>
  <div class="modal-background" :style="{ top: `${pageYOffset}px` }">
    <div class="confirm-modal">
      <div class="confirm-modal-title">
        {{props.title}}
      </div>
      <div class="info">
        <dl v-for="(item, index) in props.confirmInfo" :key="index">
          <dt>{{ item.key }}</dt>
          <dd class="text--stronger">{{ item.value }}</dd>
        </dl>
      </div>
      <div class="button-group">
        <button @click="onCancel" class="button button--cancel">いいえ</button>
        <button @click="onSubmit" class="button" :disabled="props.isLoading">
          <span :style="{ visibility: props.isLoading ? 'hidden' : 'visible' }"
            >はい</span
          >
          <IconSpinner v-show="props.isLoading" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
