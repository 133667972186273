export default {
  "MSG-COM-W-0010": "{0}には{1}桁の数字を入力して下さい。",
  "MSG-COM-W-0020": "注文された申込書番号{0}が重複しています。",
  "MSG-COM-W-0030": "申込書番号{0}は既に受注済みです。",
  "MSG-COM-W-0040": "必須の値が入力されていません。{0}",
  "MSG-COM-W-0050": "文字数が不足、または超過している項目があります。{0}",
  "MSG-COM-W-0060": "「郵便番号」「都道府県コード」「ご住所」すべてに値が設定されています。",
  "MSG-COM-W-0070": "正しい日付を入力して下さい。",
  "MSG-COM-W-0080": "配送伝票に印字できない文字が含まれます。原則、常用漢字等に修正してください。該当の文字を強くご希望の場合は手書き対応となりますので、「要対応文字」にチェックしてください。",
  "MSG-COM-W-0090": "該当する郵便局が存在しません。局所コードが誤っている可能性があります。",
  "MSG-COM-W-0100": "該当するユーザ情報が存在しません。社員コードが誤っている可能性があります。",
  "MSG-COM-E-0010": "該当する郵便局情報が存在しません。",
  "MSG-COM-E-0020": "該当するユーザ情報が存在しません。",
  "MSG-COM-E-0030": "該当するDMプレ印字情報が存在しません。",
  "MSG-COM-E-0040": "該当する郵便番号情報が存在しません。",
  "MSG-COM-E-0050": "該当する商品情報が存在しません。",
  "MSG-COM-E-0060": "該当するカタログ情報が存在しません。",
  "MSG-COM-E-0070": "該当する商品付帯グループ情報が存在しません。",
  "MSG-COM-E-0080": "該当する佐川販売商品情報が存在しません。",
  "MSG-COM-E-0090": "該当する佐川郵便番号情報が存在しません。",
  "MSG-COM-E-0100": "該当する付帯サービス詳細情報が存在しません。",
  "MSG-COM-E-0110": "該当する販売商品情報が存在しません。",
  "MSG-COM-E-0120": "該当する販売商品マスタ詳細情報が存在しません。",
  "MSG-COM-E-0130": "該当する注文情報が存在しません。",
  "MSG-COM-E-0140": "ご希望の商品は見つかりませんでした。",
  "MSG-COM-E-0150": "該当する販売商品マスタ、付帯サービス詳細、付帯サービス選択肢の情報が存在しません。",
  "MSG-COM-E-0160": "検索条件に一致する住所が多すぎるため表示できません。検索条件を見直して再度検索してください。",
  "MSG-COM-E-0170": "認証に失敗しました。",
  "MSG-COM-E-0180": "DBの接続に失敗しました。",
  "MSG-COM-E-0190": "該当する郵便局が存在しません。",
  "MSG-COM-E-0200": "S3の接続に失敗しました。",
  "MSG-COM-E-0210": "ファイル生成に失敗しました。",
  "MSG-COM-E-0220": "API Key が取得できません。",
  "MSG-COM-E-0230": "{0}APIの実行に失敗しました。",
  "MSG-COM-E-0240": "システムエラーが発生しました。",
  "MSG-COM-E-0250": "セッションアウトしました。「OK」ボタンをクリックして郵便局設定画面から操作し直してください。",
  "MSG-COM-E-0260": "郵便局情報を更新してください。",
  "MSG-COM-E-0270": "画像変換処理に失敗しました。",
  "MSG-COM-E-0280": "外字変換処理に失敗しました。",
  "MSG-COM-E-0290": "Ldapの接続に失敗しました。",
  "MSG-COM-E-0300": "通信エラーが発生しました。ネットワーク接続を確認してもう一度試してください。"
}