<script setup>
import { ref, reactive, onBeforeMount, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import useErrorModal from "@/stores/modules/errorModal";
import useCommonStore from "@/stores/modules/commonStore";
import { utf8ToBase64, base64ToUtf8, setAPIKey } from "@/utils/common"; // ユーティリティ関数
import messages from "@/constants/messages"; //メッセージ一覧
import useSessionInfo from "@/stores/modules/sessionInfo";
import { postOfficeMaster } from "@/api/postOfficeMaster";
import ConfirmModal from "@/components/ConfirmModal";
import IconLoading from "@/components/IconLoading";

const router = useRouter();
const route = useRoute();

const sessionInfoStore = useSessionInfo();
const errorModalStore = useErrorModal();
const commonStore = useCommonStore();

const postOfficeCode = ref("");
const postOfficeName = ref("");
const companyCode = ref("3001");
const companyCodeData = reactive([]);
const confirmInfo = reactive([]);
const isShowConfirmModal = ref(false);

const postOfficeCodeErrorMessage = ref(""); ///
const isLoading = ref(false);
const prePostOfficeCode = ref("");

/* ============メソッド============ */
const savePostOfficeCode = () => {
  prePostOfficeCode.value = postOfficeCode.value;
};

/* イベントNo.EV_ST_02
  局所コード入力ボックスからフォーカスアウト時のチェック */
const checkOfficeCode = () => {
  // console.log(prePostOfficeCode.value);
  if (prePostOfficeCode.value != postOfficeCode.value && postOfficeCode.value) {
    clearData();
    searchPostOffice();
  } else if (prePostOfficeCode.value == postOfficeCode.value && !postOfficeName.value && !postOfficeCodeErrorMessage.value && postOfficeCode.value) {
    searchPostOffice();
  } else if (prePostOfficeCode.value && !postOfficeCode.value) {
    clearData();
  }
};

//数字以外入力できないように制御
const fixPostOfficeCode = () => {
  const exp = /[^0-9]/g;
  !exp.test(postOfficeCode.value) && (postOfficeCodeErrorMessage.value = "");
  postOfficeCode.value = postOfficeCode.value.replace(exp, "");
};

/* イベントNo.EV_ST_03 検索 */
const searchPostOffice = () => {
  if (!/^\d{6}$/.test(postOfficeCode.value)) {
    postOfficeCodeErrorMessage.value = messages["MSG-COM-W-0010"]
      .replace("{0}", "局所コード")
      .replace("{1}", "6");
  } else {
    isLoading.value = true;
    let _postOfficeCode = postOfficeCode.value;
    /* 局所マスタAPI【API-COM-0040】を呼び出す */
    postOfficeMaster({
      postOfficeCode: _postOfficeCode,
    })
      .then((res) => {
        if (res?.data) {
          const { resultCode } = res;
          if (resultCode == "00000") {
            const { shopInfo } = res.data;
            postOfficeName.value = shopInfo[0].postOfficeName;
            shopInfo.forEach((element) => {
              if (element.companyCode == "2001") {
                companyCodeData[1] = "2001";
                companyCode.value = "2001";
              }
              if (element.companyCode == "3001") {
                companyCodeData[0] = "3001";
                companyCode.value = "3001";
              }
            });
          } else if (resultCode == "I0030") {
            const errorCode = res.data.errorInfo[0].errorCode;
            postOfficeCodeErrorMessage.value = messages[errorCode];
          } else {
            /* ほかの想定外の状況はシステムエラーで処理 */
            errorModalStore.isShow = true;
            errorModalStore.message = messages["MSG-COM-E-0240"];
            clearData();
          }
          if (res.data.recordCount == 2) {
            companyCode.value = "3001";
          }
        }
      })
      .finally(() => {
        isLoading.value = false;
      });
  }
};

const changecompanyCode = (code) => {
  code && (companyCode.value = code);
};

//セッション情報の初期化（クリア）
const clearData = () => {
  postOfficeCodeErrorMessage.value = "";
  postOfficeName.value = "";
  companyCode.value = "3001";
  companyCodeData.splice(0, companyCodeData.length);
  commonStore.clearData();
};

/* イベントNo.EV_DY_01
  取扱局設定情報をセッションに保存し、担当者選択画面へ遷移する */
const gotoResponsibleSetting = () => {
  confirmInfo.splice(0, confirmInfo.length);
  confirmInfo.push({
    key: "郵便局名",
    value: `${postOfficeCode.value} ${postOfficeName.value}`,
  });
  confirmInfo.push({
    key: "営業区分",
    value: `${
      companyCode.value == "3001" ? "郵便窓口" : "集配・ゆうゆう窓口等"
    }`,
  });
  isShowConfirmModal.value = true;
};

const handleGotoResponsibleSetting = () => {
  isShowConfirmModal.value = false;
  sessionInfoStore.postOfficeCode = utf8ToBase64(postOfficeCode.value);
  sessionInfoStore.postOfficeName = utf8ToBase64(postOfficeName.value);
  sessionInfoStore.companyCode = utf8ToBase64(companyCode.value);
  sessionInfoStore.companyCodeData = utf8ToBase64(companyCodeData);
  router.replace("/responsible-setting");
};

const hideConfirmModel = () => {
  isShowConfirmModal.value = false;
};

// モーダル表示時スクロールバーを隠す
watch(
  () => isShowConfirmModal.value,
  (newVal) => {
    if (newVal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }
);

// エラーメッセージモーダル表示時確認モーダルを閉じる
watch(
  () => errorModalStore.isShow,
  (newVal) => {
    if (newVal == true) {
      isShowConfirmModal.value = false;
    }
  }
);

/* ============ライフサイクルフック============ */
onBeforeMount(() => {
  commonStore.isWithLogo = route.meta.withLogo;
});
onMounted(() => {
  // CTC環境であるかどうかを判断
  if (process.env.VUE_APP_ENV_MODE == "CTC") {
    try {
      /* イベントNo.EV_ST_00
        URLスキームでAPI Keyを取得する
        ネイティブアプリ側からsetAPIKeyを呼び出し、APIKeyを渡してくれる  */
      // window.location.href = "catalogsalesapp://redirect/getAPIKeyFromApp";
      // TODO: 開発環境用の暫定対応で固定値設定
      // setAPIKey(utf8ToBase64(process.env.API_KEY));
      //setAPIKey(utf8ToBase64("tr.jpcs-bpn.jp.E2D2LU3SKKZGTI"));
      setAPIKey(utf8ToBase64("dev.jpcs-bpn.jp.E1T1IPTXGREXJF"));
    } catch (messageCode) {
      /* ネイティブアプリ側から文字列以外の値を渡してくれるとエラーになる
      イベントNo.EV_ST_00 1.5 */
      errorModalStore.isShow = true;
      errorModalStore.message = messages[messageCode];
      return;
    }
  } else {
    console.log("NSD環境setAPIKey");
    setAPIKey(utf8ToBase64("APIKey"));
  }

  /* イベントNo.EV_ST_01 画面項目の値の初期化
  セッションから取得した情報の有効性チェックと復号化*/
  try {
    sessionInfoStore?.postOfficeCode &&
      (postOfficeCode.value = base64ToUtf8(sessionInfoStore.postOfficeCode));
    sessionInfoStore?.postOfficeName &&
      (postOfficeName.value = base64ToUtf8(sessionInfoStore.postOfficeName));
    sessionInfoStore?.companyCode &&
      (companyCode.value = base64ToUtf8(sessionInfoStore.companyCode));
    sessionInfoStore?.companyCodeData &&
      companyCodeData.splice(
        0,
        companyCodeData.length,
        ...base64ToUtf8(sessionInfoStore?.companyCodeData)
      );
  } catch (err) {
    // 無効の場合、セッションデータをクリア
    clearData();
  }

  /* イベントNo.EV_ST_01
    取扱局設定情報が存在且つ担当者選択画面から遷移来たフラグがない時、担当者選択画面に遷移  */
  var url = new URL(window.location.href);
  var dirFlg = url.searchParams.get('dirFlg');
  var mngFlg = url.searchParams.get('mngFlg');
  // ダイレクト注文 or 管理画面から戻ってきた場合
  if ((dirFlg != undefined && dirFlg == 'true') || (mngFlg != undefined && mngFlg == 'true')) {
    // 機能選択画面に遷移する
    router.replace("/function-selection");
  } else if (
    !sessionInfoStore.returnFlag &&
    sessionInfoStore.postOfficeName &&
    sessionInfoStore.postOfficeCode &&
    sessionInfoStore.companyCode &&
    sessionInfoStore.companyCodeData
  ) {
    router.replace("/responsible-setting");
  }
  sessionInfoStore.returnFlag = false;
});
</script>

<template>
  <div class="body-container-inner">
    <ConfirmModal
      v-show="isShowConfirmModal"
      :title="'郵便局を設定します。よろしいですか？'"
      :confirmInfo="confirmInfo"
      :onCancel="hideConfirmModel"
      :onSubmit="handleGotoResponsibleSetting"
    />
    <div class="contents-area">
      <div class="image-illustration">
         <img src="@/assets/images/img1.png" alt="" />
      </div>
      <h1 class="body-title">郵便局の設定</h1>
      <div class="text-exposition">
        <p>このアプリを使用する郵便局を設定してください</p>
      </div>

      <div class="form-field">
        <div class="form-field-lable">
          <label>1. 郵便局を指定してください</label>
        </div>
        <div class="form-field-input">
          <input
            class="text-box"
            :class="{ 'text-box_error': postOfficeCodeErrorMessage }"
            type="text"
            placeholder="局所コードを入力してください"
            maxlength="6"
            @focus="savePostOfficeCode"
            @blur="checkOfficeCode"
            @input="fixPostOfficeCode"
            v-model="postOfficeCode"
          />
        </div>
        <div class="error-message" v-show="postOfficeCodeErrorMessage">
          {{ postOfficeCodeErrorMessage }}
        </div>
        <div class="form-field-info-area">
          <dl>
            <dt>郵便局名&nbsp;:&nbsp;</dt>
            <dd>{{ postOfficeName }}</dd>
          </dl>
        </div>
      </div>

      <div class="form-field">
        <div class="form-field-lable">
          <label>2. 営業区分を選択してください</label>
        </div>
        <div class="form-field-radio-group">
          <div
            class="form-field-radio-set"
            @click="changecompanyCode(companyCodeData[0])"
          >
            <input
              class="form-field-radio"
              type="radio"
              :value="companyCodeData[0]"
              :checked="(companyCodeData[0] || '3001') == companyCode"
              :disabled="!companyCodeData[0]"
            />
            <div class="form-field-radio__icon">
              <div class="form-field-radio__icon--dot"></div>
            </div>
            <label>郵便窓口</label>
          </div>
          <div
            class="form-field-radio-set"
            @click="changecompanyCode(companyCodeData[1])"
          >
            <input
              class="form-field-radio"
              type="radio"
              :value="companyCodeData[1]"
              :checked="companyCodeData[1] == companyCode"
              :disabled="!companyCodeData[1]"
            />
            <div class="form-field-radio__icon">
              <div class="form-field-radio__icon--dot"></div>
            </div>
            <label>集配・ゆうゆう窓口等</label>
          </div>
        </div>
      </div>
    </div>
    <div class="icon-searching"><IconLoading :style="{ visibility: !isLoading ? 'hidden' : 'visible' }" /></div>

    <div class="button-area">
      <button
        class="button button_long"
        @click="gotoResponsibleSetting"
        :disabled="!postOfficeName"
      >
        設定する
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
