import { defineStore } from "pinia"

export default defineStore("sessionInfo", {
  state: () => ({
    postOfficeName: "",
    postOfficeCode: "",
    companyCode: "",
    companyCodeData: "",
    returnFlag: false
  }),
  actions: {
    /* セッション情報のクリア */
    clearSessionInfo() {
      this.postOfficeName = ""
      this.postOfficeCode = ""
      this.companyCode = ""
      this.companyCodeData = ""
      localStorage.removeItem("sessionInfo")
    }
  },
  persist: true
})