import { createRouter, createWebHistory } from "vue-router"
import { setPageTitle } from "@/utils/common"

import PostOfficeSetting from '@/views/PostOfficeSetting'
const ResponsibleSetting = () => import('@/views/ResponsibleSetting')
const FunctionSelection = () => import('@/views/FunctionSelection')

const routes = [
  {
    path: '/',
    component: PostOfficeSetting,
    meta: {
      title: '郵便局設定',
      withLogo: false
    }
  },
  {
    path: '/post-office-setting',
    component: PostOfficeSetting,
    meta: {
      title: '郵便局設定',
      withLogo: false
    }
  },
  {
    path: '/responsible-setting',
    component: ResponsibleSetting,
    meta: {
      title: '担当者選択',
      withLogo: false
    }
  },
  {
    path: '/function-selection',
    component: FunctionSelection,
    meta: {
      title: '機能選択',
      withLogo: true
    }
  },
  //存在しないURLにアクセスした場合、「郵便局設定」画面へリダイレクト
  {
    path: '/:pathMatch(.*)',
    component: PostOfficeSetting,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, _, next) => {
  setPageTitle(to.meta.title)
  window.scrollTo({ top: 0 })
  next()
})

export default router