<template>
  <div class="icon-loading"><span></span><span></span><span></span></div>
</template>
<style scoped lang="scss">
.icon-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  span {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    display: inline-block;
    border-radius: 50%;
    background-color: var(--base-color-text);
    animation: opacity 1s linear infinite;
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.25s;
    }
    &:nth-child(3) {
      animation-delay: 0.5s;
    }
  }
}
@keyframes opacity {
  0% {
    opacity: 0.8;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
</style>
