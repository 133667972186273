import { defineStore } from "pinia"
import useSessionInfo from "./sessionInfo";
import useUserUsageHistory from "./userUsageHistory";
import useSuggestUserList from "./suggestUserList";
import Cookies from "js-cookie";

export default defineStore("commonStore", {
  state: () => ({
    isWithLogo: false,
  }),
  actions: {
    /* システムエラー時セッション情報などのクリア */
    clearData() {
      const sessionInfoStore = useSessionInfo()
      const userUsageHistoryStore = useUserUsageHistory()
      const SuggestUserListStore = useSuggestUserList()
      sessionInfoStore.clearSessionInfo()
      userUsageHistoryStore.clearUseUserUsageHistory()
      SuggestUserListStore.clearUseSuggestUserList()
      Cookies.remove("IdToken")
    }
  },
})
