import { defineStore } from "pinia"

const useSuggestUserList = defineStore("suggestUserList", {
  state: () => ({
    data: [
      // {
      //   "userLoginId": "12345670",
      //   "displayName": "太郎",
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "34567890",
      //   "displayName": "次郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "34567333",
      //   "displayName": "三郎",
      //   "companyCode": "3001"
      // },

      // {
      //   "userLoginId": "32227890",
      //   "displayName": "四郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "34565550",
      //   "displayName": "五郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "66667890",
      //   "displayName": "六郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "66667897",
      //   "displayName": "七郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "66667898",
      //   "displayName": "八郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "12345678",
      //   "displayName": "九郎",
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "87654322",
      //   "displayName": "十朗",
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "12775678",
      //   "displayName": "小九郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "11167890",
      //   "displayName": "士郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "22267890",
      //   "displayName": "吾郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "44567890",
      //   "displayName": "小五郎",
      //   "companyCode": "3001"
      // },
      // {
      //   "userLoginId": "23456789",
      //   "displayName": "花子",//"該当するユーザ情報が存在しません。"
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "87654321",
      //   "displayName": "テストユーザー",//認証に失敗しました
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000001",
      //   "displayName": "テストユーザー１",//認証認可APIの実行に局所コードのパラメータチェックエラーが発生
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000002",
      //   "displayName": "テストユーザー２",//認証認可APIの実行に局所コードのパラメータチェックエラーが発生
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000003",
      //   "displayName": "テストユーザー３",//認証認可APIの実行に接続エラーが発生
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000004",
      //   "displayName": "テストユーザー４",//
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000005",
      //   "displayName": "テストユーザー５",//
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000006",
      //   "displayName": "テストユーザー６",//
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000007",
      //   "displayName": "テストユーザー７",//
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000008",
      //   "displayName": "テストユーザー８",//
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "90000009",
      //   "displayName": "テストユーザー９",//
      //   "companyCode": "2001"
      // },
      // {
      //   "userLoginId": "91000000",
      //   "displayName": "ユーザ名二十五文字上限テストユーザ名二十五文字上限",//
      //   "companyCode": "2001"
      // },
    ]
  }),
  actions: {
    clearUseSuggestUserList() {
      this.data = []
    }
  },
})

export default useSuggestUserList