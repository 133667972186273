import { defineStore } from "pinia"

const useUserUsageHistory = defineStore("userUsageHistory", {
  state: () => ({
    data: []
  }),
  actions: {
    /* ユーザ利用履歴のクリア */
    clearUseUserUsageHistory() {
      this.data = []
      localStorage.removeItem("userUsageHistory")
    }
  },
  persist: true
})

export default useUserUsageHistory