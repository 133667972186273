import axios from "axios";
import pinia from '@/stores'
import useErrorModal from "@/stores/modules/errorModal";
import useCommonStore from "@/stores/modules/commonStore";
import messages from "@/constants/messages";
import APIInfo from "@/constants/APIInfo";
import Cookies from "js-cookie";
import router from "@/router"

const errorModalStore = useErrorModal(pinia)
const commonStore = useCommonStore(pinia)
const baseURL = process.env.VUE_APP_API_BASE_URL;
const APIKey = Cookies.get("APIKey")

const requset = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': "application/json"
  }
})

/* リクエストの共通処理 */
requset.interceptors.request.use((config) => {
  if (APIKey) {
    config.headers["X-API-Key"] = APIKey
  }
  return config
}, (err) => {
  console.log(err);
})

/* レスポンスの共通処理 */
requset.interceptors.response.use((res) => {
  const { resultCode } = res.data;
  if (resultCode == "I0010") {
    const errorMessage = res.data.data.errorInfo[0].errorMessage || res.data.data.errorInfo[0].errorMeassage;
    errorModalStore.isShow = true;
    errorModalStore.message = errorMessage;
  } else {
    return res.data
  }
}, (err) => {
  const apiURL = err.config.url.split("/")
  const apiField = apiURL[apiURL.length - 1]
  if (err.response) {
    const { status } = err.response
    const errorCode = err.response.data.data?.errorInfo[0].errorCode
    if (400 <= status && status < 500) {
      errorModalStore.isShow = true;
      if (status == 401 && apiField == "auth") {
        errorModalStore.message = messages[errorCode];
      } else {
        errorModalStore.message = messages["MSG-COM-E-0240"];
      }
      errorModalStore.submit = () => {
        commonStore.clearData();
        router.replace("/post-office-setting");
      };
    } else if (500 <= status && status < 600) {
      errorModalStore.isShow = true;
      errorModalStore.message = messages["MSG-COM-E-0230"].replace("{0}", APIInfo[apiField]);
    } else {
      //ほかの想定外エラー共通処理
      errorModalStore.isShow = true;
      errorModalStore.message = messages["MSG-COM-E-0240"];
      errorModalStore.submit = () => {
        commonStore.clearData();
        router.replace("/post-office-setting");
      };
    }
  }
  else if (err.request) {
    //通信エラー共通処理
    errorModalStore.isShow = true;
    errorModalStore.message = messages["MSG-COM-E-0300"];
  } else {
    //ほかの想定外エラー共通処理
    errorModalStore.isShow = true;
    errorModalStore.message = messages["MSG-COM-E-0240"];
    errorModalStore.submit = () => {
      commonStore.clearData();
      router.replace("/post-office-setting");
    };
  }
  if (apiField == "user-list") {
    throw new Error("getUseListError")
  }
})

export default requset;