import { toByteArray, fromByteArray } from "base64-js";
import Cookies from "js-cookie";

/* ネイティブアプリ側から呼び出して、APIKeyを設定する
イベントNo.EV_ST_00 1.2 1.3
*/
export const setAPIKey = (APIKey) => {
  if (typeof APIKey === "string") {
    // CTC環境であるかどうかを判断
    if (process.env.VUE_APP_ENV_MODE == "CTC") {
      /*API KeyをCookieに保存する
       イベントNo.EV_ST_00 1.4 */
      Cookies.set("APIKey", APIKey, {
        expires: 7,
        domain: process.env.VUE_APP_COOKIE_DOMAIN,
        secure: true,
      });
      // TODO: 開発環境用の暫定対応で固定値設定
      // TODO: APIKey = COOKIE_CF_KEYではないっぽいので、引数のAPIKeyを設定するのはNG
      //Cookies.set("COOKIE_CF_KEY", process.env.COOKIE_CF_KEY, {
      Cookies.set("COOKIE_CF_KEY", "ZGlyZWN0LmRldi5qcGNzLWJwbi5qcC5FMjQzM0I3WlpLU0xOSw==", {
        expires: 7,
        domain: process.env.VUE_APP_COOKIE_DOMAIN,
        secure: true,
      });
    } else {
      /*API KeyをCookieに保存する
       イベントNo.EV_ST_00 1.4 */
      Cookies.set("APIKey", APIKey, {
        expires: 7,
      });
      Cookies.set("COOKIE_CF_KEY", APIKey, {
        expires: 7,
      });
    }
  } else {
    throw "MSG-COM-E-0220";
  }
};

export const utf8ToBase64 = (obj) => {
  const byteArray = new TextEncoder().encode(JSON.stringify(obj));
  return fromByteArray(byteArray);
};

export const base64ToUtf8 = (base64Text) => {
  const byteArray = toByteArray(base64Text);
  return JSON.parse((new TextDecoder().decode(byteArray)));
};

/* 日付フォーマット */
export const dateFormat = (date, separator) => {
  date = new Date(date);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}${separator ? separator : "/"}${month}${separator ? separator : "/"
    }${day}`;
};

/* 画面更新 */
export const refreshPage = () => {
  window.location.reload();
};

/* オブジェクトに空き値があるかどうかをチェック */
export const hasEmptyValue = (obj) => {
  for (let key in obj) {
    const value = obj[key];
    if (key == "returnFlag") return;
    if (value == undefined || value == "") {
      return true;
    }
  }
  return false;
};

/* 画面タイトルの設定 */
export const setPageTitle = (txt) => {
  document.title = `${process.env.VUE_APP_TITLE} | ${txt}`;
};

/* セッションIDを取得する */
export const getSessionId = (accessToken) => {
  return "SID-CCO:" + accessToken;
}

/* リクエストIDを取得する */
export const getRequestId = (accessToken) => {
  if (accessToken) {
    return "RID-CCO:" + accessToken + "-" + getStartOperationTime();
  } else {
    return "RID-CCO:" + getStartOperationTime();
  }
}

/* マイクロ秒を取得して62進数化した値を返却する */
export const getStartOperationTime = () => {
  var num = (performance.timeOrigin + performance.now()).toFixed(3) * 1000;
  let ret = "";
  let digit = 0;
  let firstFlg = true;
  while(firstFlg || num >= 62){
    firstFlg = false;
    digit = num % 62;
    if (digit < 10)
      ret+=String(digit); // 0-9
    else if (digit < 36)
      ret+=String.fromCharCode(digit + 87); // a-z
    else
      ret+=String.fromCharCode(digit + 29); // A-Z
    if (num < 62)
      break;
    else
      num = Math.floor(num / 62);
  }
  return ret.split("").reverse().join("");
}