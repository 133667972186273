import api from "./api"

//(API-COM-0040)_局所マスタ参照API
export const postOfficeMaster = (params, hostName) => {
  const url = "/refer/post-office-master"
  const data = { ...params, hostName }

  console.log("send data：", data); // 打印请求参数
  alert("发送的请求参数：" + JSON.stringify(data)); // 使用alert弹出请求参数
  return api.request({
    url,
    method: "post",
    data,
  })
}