<template>
  <div class="icon-spinner">
    <div class="icon-spinner-innder"></div>
  </div>
</template>
<style scoped lang="scss">
.icon-spinner {
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  &-innder {
    width: 18px;
    height: 18px;
    border: 2.5px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
    position: relative;
    animation: rotate 4.5s linear infinite;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
